import { Loading } from '@components/loading/Loading'
import { API_VERSION } from 'consts'
import { FatalError } from 'errors/FatalError'
import { jsonFetch } from 'http/http'
import { genericRetryStrategy } from 'http/retryEstrategy'
import i18next from 'i18next'
import { useObservable, useObservableGetState } from 'observable-hooks'
import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { catchError, retryWhen } from 'rxjs'
import { Config } from 'Types'
import { generateURL } from 'utils/FormatUtil'
import { useEmbedParams } from './EmbedParamsProvider'

type ConfigProviderProps = PropsWithChildren<{
  //
}>

type ConfigContextData = Config | undefined

const ConfigContext = React.createContext<ConfigContextData>(undefined)

const configRetryStrategy = genericRetryStrategy({
  maxRetryAttempts: 1,
})
export default function ConfigProvider(props: ConfigProviderProps) {
  const { liveId, liveUrl, customerId, configEndpoint } = useEmbedParams()

  const { children } = props

  const request$ = useObservable(() => {
    return jsonFetch<Config>(
      generateURL(configEndpoint || 'http://config-endpoint-not-set', {
        MIMO_LIVE_ID: liveId!,
        MIMO_API_VERSION: API_VERSION,
        MIMO_CUSTOMER_ID: customerId ?? '',
        MIMO_URL_LIVE: liveUrl ?? '',
      })
    ).pipe(retryWhen(configRetryStrategy))
  }, [liveId, customerId, liveUrl])

  const config = useObservableGetState(
    request$.pipe(
      catchError((err: Error) => {
        throw new FatalError(
          `Não foi possível carregar as configurações da live: ${err.message} `,
          err
        )
      })
    ),
    undefined
  )

  useEffect(() => {
    if (config) {
      fetch(
        import.meta.env.VITE_BILLING +
          `/customer/${config.customer_id}/features`
      )
        .then((res) => res.json())
        .then((features) => {
          const waterFeature: any = features?.filter(
            (item: any) =>
              item.feature_slug === 'water_mark' && item.product_type === 'live'
          )

          config.water_mark =
            waterFeature &&
            waterFeature?.length !== 0 &&
            waterFeature?.[0]?.limit !== 0
              ? true
              : false
        })
        .catch((err: Error) => {
          throw new FatalError(
            `Não foi possível carregar as configurações da live: ${err.message} `,
            err
          )
        })
    }
  }, [config])

  i18next.changeLanguage(config?.settings.language)

  return (
    <ConfigContext.Provider value={config}>
      {!config && <Loading />}
      {config && children}
    </ConfigContext.Provider>
  )
}

// force nonNullable casting because the consumer will always have a valid config
export function useConfig() {
  return useContext<NonNullable<ConfigContextData>>(
    ConfigContext as React.Context<NonNullable<ConfigContextData>>
  )
}
