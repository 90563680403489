import FloatingButton from '@components/floatingButton/FloatingButton'
import HotProductCard from '@components/hotProductCard/HotProductCard'
import { MuteIcon } from '@icons/MuteIcon'
import { UnMuteIcon } from '@icons/UnMuteIcon'
import { isArray } from 'lodash'
import { useChat } from 'providers/ChatProviders'
import { useProducts } from 'providers/ProductsProvider'
import { useSettings } from 'providers/SettingsProviders'
import { useVideo } from 'providers/VideoProvider'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { PATHS } from 'router/Paths'
import LiveTemplate from 'templates/liveTemplate/LiveTemplate'
import { MimoId, Product, Products, ProductVariant } from 'Types'
import { getProductThumb } from 'utils/ProductUtil'
import LiveCountFirebase from '../../components/liveCount/LiveCountFirebase'
import TextLive from '../../components/textLive/TextLive'
import { useProductsEnabled } from '../../hooks/useProductsHooks'
import { useAccessibility } from '../../providers/AccessibilityProvider'
import { useConfig } from '../../providers/ConfigProvider'
import { useTracking } from '../../providers/TrackingProvider'
import { useMimoRouter } from '../../router/MimoRouter'
import { useSelector } from '../../store/Store'

import { HeartSwitcher } from '../../components/HeartSwitcher/HeartSwitcher'
import LikeIconLoading from '../../icons/LikeIconLoading.svg'
import { LikeAnimation } from '../likeAnimation/LikeAnimation'

function Top() {
  const { settings } = useSettings()
  const config = useConfig()

  const ended = settings?.live.status == 'ended'
  const recordedLive = Boolean(config?.recorded_live) && ended

  if (settings?.live.status !== 'live' && !recordedLive) {
    return null
  }

  return (
    <div className="inline-flex flex-row space-x-2">
      <TextLive recordedLive={recordedLive} />
      <LiveCountFirebase />
    </div>
  )
}

export default function DesktopLiveOverlay() {
  const top = <Top />
  const middle = <></>
  const bottom = <HotProducts />
  const right = <Right />

  return (
    <LiveTemplate top={top} middle={middle} bottom={bottom} right={right} />
  )
}

function Right() {
  const { settings } = useSettings()
  const config = useConfig()
  const { accessibility } = useAccessibility()

  const { t } = useTranslation()

  const video = useVideo()

  const ended = settings?.live.status == 'ended'
  const recordedLive = Boolean(config?.recorded_live) && ended

  return (
    <div
      className={`ml-2 flex flex-col items-center h-full space-y-2  ${
        config.water_mark ? 'pb-[22px]' : 'pb-0'
      }`}
    >
      <div className="flex-1">
        {/* empty space, might be used in the future */}
      </div>

      <div
        className={`flex flex-col rounded-[40px] text-immutableBlack py-[10px] px-[5px] ${
          accessibility.highContrast
            ? 'bg-primary'
            : 'bg-immutableWhite-bgop bg-opacity-75'
        }`}
      >
        {!ended && (
          <>
            <LikeAnimation />
            <LikeButton isDisabled={recordedLive} />
          </>
        )}

        <FloatingButton
          onClick={video.intentToggleMute}
          circled={false}
          ariaLabel={video.muted ? t('activate_sound') : t('silence')}
          icon={video.muted ? <MuteIcon /> : <UnMuteIcon />}
          label={t('sound')}
        />
      </div>
    </div>
  )
}

function HotProducts() {
  const settings = useSettings()
  const config = useConfig()

  const productsEnabled = useProductsEnabled()

  const ended = settings.settings?.live.status == 'ended'

  const isRecorded = ended && Boolean(config?.recorded_live)

  const hotProductRecorded = useSelector((v) => v.video.hotProduct)

  const { go } = useMimoRouter()

  const { t } = useTranslation()

  const hot =
    (isRecorded
      ? JSON.stringify(hotProductRecorded)
      : settings?.settings?.hot) || '[]'

  const products = useProducts()
  const tracking = useTracking()
  const currency = config?.settings?.currency?.code

  const hotArray = useMemo<HotData[]>(() => {
    let hotIdList: MimoId[]
    try {
      hotIdList = JSON.parse(hot)
      if (!isArray(hotIdList)) {
        throw new Error(`Invalid "hot" setting "${hot}", must be an array`)
      }
    } catch (e) {
      console.error(e)
      return []
    }
    return hotIdList
      .map((hotVariantId) => {
        return getHotData(products, hotVariantId)
      })
      .filter((v) => !!v)
  }, [hot, products])

  if ((!config?.recorded_live && ended) || !productsEnabled) {
    return null
  }

  return (
    <div
      role="list"
      aria-label={t('highlighted_products')}
      aria-hidden={hotArray.length === 0}
      className="mb-[20px]"
    >
      <div>
        {hotArray.map((v, i) => (
          <div key={i} className="inline-block w-20 mr-2 mt-2">
            <HotProductCard
              title={v?.product.title}
              image={getProductThumb(v?.product, v?.variant)}
              price={v?.variant.price || 0}
              onClick={() => {
                tracking.track({
                  event: 'view_item',
                  currency: currency,
                  value: v?.variant.price || 0,
                  type: 'featured',
                  items: [
                    {
                      item_id: v?.variant.sku,
                      item_name: v?.product.title,
                      currency: currency,
                      price: v?.variant.price,
                      image: getProductThumb(v?.product, v?.variant),
                    },
                  ],
                })
                go(
                  generatePath(PATHS.productWithVariant, {
                    id: v?.product.id,
                    variantId: v?.variant.id,
                  })
                )
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

type HotData = {
  product: Product
  variant: ProductVariant
} | null

function getHotData(products: Products, variantId: MimoId): HotData {
  let foundVariant: ProductVariant | undefined

  const foundProduct = products.find((product) => {
    const variant = product.variants.find((v) => v.id === variantId)

    foundVariant = variant || foundVariant
    return !!variant
  })

  if (!foundProduct || !foundVariant) {
    return null
  }

  return { product: foundProduct, variant: foundVariant }
}

type DisableLikeButton = {
  isDisabled?: boolean
}

function LikeButton({ isDisabled }: DisableLikeButton) {
  const { t } = useTranslation()
  const { like, isSendingLike } = useChat()
  const tracking = useTracking()

  const [imageUrl, setImageUrl] = useState(LikeIconLoading)
  const [timerForLike, setTimerForLike] = useState(false)

  const refreshImage = () => {
    setTimerForLike(true)

    setImageUrl((prevUrl) => `${prevUrl}?${Date.now()}`)

    setTimeout(() => {
      setTimerForLike(false)
    }, 2000)
  }

  return (
    <div className="bg-opacity-75 text-immutableBlack">
      <FloatingButton
        onClick={(v) => {
          like(v)
          tracking.track({ event: 'like_button', event_label: 'Like Button' })
          refreshImage()
        }}
        circled={false}
        icon={
          <HeartSwitcher
            isLoading={isSendingLike || timerForLike}
            imageUrl={imageUrl}
          />
        }
        disabled={isDisabled || timerForLike}
        ariaLabel={
          timerForLike
            ? t(
                'Several red hearts appear on the screen, indicating that you have successfully completed the action.'
              )
            : t('like')
        }
        label={t('like')}
      />
    </div>
  )
}
