import FloatingButton from '@components/floatingButton/FloatingButton'
import HotProductCard from '@components/hotProductCard/HotProductCard'
import LiveHeader from '@components/liveHeader/LiveHeader'
import { MuteIcon } from '@icons/MuteIcon'
import { UnMuteIcon } from '@icons/UnMuteIcon'
import { MimoId, Product, ProductVariant, Products } from 'Types'
import ChatList from 'containers/chatList/ChatList'
import { ShareButton } from 'containers/shareButton/ShareButton'
import { isArray } from 'lodash'
import { useChat } from 'providers/ChatProviders'
import { useCheckout } from 'providers/CheckoutProvider'
import { useProducts } from 'providers/ProductsProvider'
import { useSettings } from 'providers/SettingsProviders'
import { useVideo } from 'providers/VideoProvider'
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { PATHS } from 'router/Paths'
import LiveTemplate from 'templates/liveTemplate/LiveTemplate'
import { getProductThumb } from 'utils/ProductUtil'
import { AccessibilityMenu } from '../../components/AccessibilityMenu'
import BulletButtonColor from '../../components/bulletButtonColor/BulletButtonColor'
import LiveCountFirebase from '../../components/liveCount/LiveCountFirebase'
import TextLive from '../../components/textLive/TextLive'
import { useChatEnabled } from '../../hooks/useChatHooks'
import { useCheckoutEnabled } from '../../hooks/useCheckoutHooks'
import { useProductsEnabled } from '../../hooks/useProductsHooks'
import { BagIcon } from '../../icons/BagIcon'
import { ChatOFFIcon } from '../../icons/ChatOFFIcon'
import { ChatONIcon } from '../../icons/ChatONIcon'
import { PlusIcon } from '../../icons/PlusIcon'
import { useAccessibility } from '../../providers/AccessibilityProvider'
import { useConfig } from '../../providers/ConfigProvider'
import { useTracking } from '../../providers/TrackingProvider'
import { useMimoRouter } from '../../router/MimoRouter'
import { useSelector } from '../../store/Store'
import { ChatInputControl } from '../chatInputControl/ChatInputControl'
import { LikeAnimation } from '../likeAnimation/LikeAnimation'

import { HeartSwitcher } from '../../components/HeartSwitcher/HeartSwitcher'
import LikeIconLoading from '../../icons/LikeIconLoading.svg'

export default function LiveOverlay() {
  const top = <Top />
  const middle = <Middle />
  const bottom = <Bottom />
  const right = <Right />

  return (
    <LiveTemplate top={top} middle={middle} bottom={bottom} right={right} />
  )
}

function Right() {
  const { settings } = useSettings()
  const config = useConfig()
  const { accessibility } = useAccessibility()

  const checkoutEnabled = useCheckoutEnabled()

  const video = useVideo()
  const chat = useChat()

  const { go } = useMimoRouter()

  const openProducts = useCallback(() => {
    go(PATHS.products)
  }, [go])

  const checkout = useCheckout()

  const productsEnabled = useProductsEnabled()

  const ended = settings?.live.status == 'ended'
  const recordedLive = config?.recorded_live && ended

  const { t } = useTranslation()

  const [firstPlay, setFirstPlay] = useState<boolean>(false)
  const [mutedFlasher, setMutedFlasher] = useState<boolean>(false)
  const tracking = useTracking()

  const showMutedFlasher = useCallback(() => {
    setTimeout(() => {
      if (video.muted && !firstPlay) {
        setFirstPlay(true)
        setMutedFlasher(true)
      }
    }, 10000)
  }, [firstPlay, video])

  return (
    <div
      className={`ml-2 flex flex-col items-center h-full ${
        recordedLive ? 'pt-7' : 'pt-1'
      } ${config.water_mark ? 'pb-[22px]' : 'pb-0'}`}
    >
      {checkoutEnabled && checkout.renderCartButton()}

      {!!settings?.uiSettings.showAccessibility && (
        <div className="mt-[12px]">
          <AccessibilityMenu type="mobile" className="text-text" />
        </div>
      )}

      {!!settings?.live.shareURL && !!settings.uiSettings.showShare && (
        <div className="mt-[12px]">
          <ShareButton />
        </div>
      )}

      <div className="flex-1">
        {/* empty space, might be used in the future */}
      </div>

      {video.playing && showMutedFlasher()}
      <div
        className={`flex flex-col rounded-[40px] text-immutableBlack py-[14px] px-[5px] mb-3 ${
          accessibility.highContrast
            ? 'bg-primary'
            : 'bg-immutableWhite-bgop bg-opacity-75'
        }`}
      >
        {!ended && (
          <>
            <LikeAnimation />
            <LikeButton isDisabled={recordedLive} />
          </>
        )}

        <FloatingButton
          onClick={video.intentToggleMute}
          circled={false}
          flasher={mutedFlasher}
          ariaLabel={video.muted ? t('activate_sound') : t('silence')}
          label={t('sound')}
          icon={video.muted ? <MuteIcon /> : <UnMuteIcon />}
        />

        {productsEnabled && (
          <FloatingButton
            onClick={() => {
              openProducts()
              tracking.track({
                event: 'open_products_list',
                event_label: 'Open Products list',
              })
            }}
            circled={false}
            label={t('products')}
            icon={<BagIcon />}
          />
        )}

        {!ended && settings?.uiSettings.showChat && (
          <FloatingButton
            onClick={chat.toggleVisibility}
            circled={false}
            label={t('chat')}
            icon={chat.visible ? <ChatOFFIcon /> : <ChatONIcon />}
          />
        )}
      </div>
    </div>
  )
}

function Top() {
  const { settings } = useSettings()
  const config = useConfig()

  const ended = settings?.live.status === 'ended'

  const shouldShowRecording = Boolean(config?.recorded_live) && ended

  return (
    <div className={`${shouldShowRecording ? 'pt-8' : 'pt-2'}`}>
      <LiveHeader
        image={settings?.customer.logo}
        title={
          (!!settings?.uiSettings.showCustomerName &&
            settings?.customer.tradingName) ||
          undefined
        }
        subTitle={
          (!!settings?.uiSettings.showLiveTitle && settings?.live.title) ||
          undefined
        }
        showImage={settings?.uiSettings.showCustomerLogo}
      />

      {(settings?.live.status == 'live' || shouldShowRecording) && (
        <div className="inline-flex flex-row space-x-2 mt-1 mb-4">
          <TextLive recordedLive={shouldShowRecording} isMobile />
          <LiveCountFirebase />
        </div>
      )}
    </div>
  )
}

function Middle() {
  const router = useMimoRouter()
  const { t } = useTranslation()
  const tracking = useTracking()
  const productsEnabled = useProductsEnabled()
  const settings = useSettings()
  const config = useConfig()

  const ended = settings.settings?.live.status == 'ended'
  const isRecorded = ended && config?.recorded_live

  const hotProductRecorded = JSON.stringify(
    useSelector((v) => v.video.hotProduct)
  )

  const hotProducts = JSON.parse(
    (isRecorded ? hotProductRecorded : settings?.settings?.hot) || '[]'
  )

  return (
    <div className="space-y-2 w-[80px] mb-4 z-2-overlay">
      {productsEnabled && (
        <ShowHighlight hot={hotProducts.length > 0}>
          <HotProducts />

          <BulletButtonColor
            onClick={() => {
              tracking.track({
                event: 'open_products_list',
                event_label: 'Open Products list',
              })
              router.go(PATHS.products)
            }}
          >
            + {t('products')}
          </BulletButtonColor>
        </ShowHighlight>
      )}
    </div>
  )
}

function Bottom() {
  const chat = useChat()
  const config = useConfig()
  const chatEnabled = useChatEnabled()

  const [showInputMsg, setShowInputMsg] = useState(true)

  const isRecorded = config?.recorded_live

  useEffect(() => {
    if (isRecorded && !chatEnabled) setShowInputMsg(false)
    else setShowInputMsg(true)
  }, [showInputMsg, chatEnabled])

  if (!isRecorded && !chatEnabled) {
    return null
  }

  return (
    <div
      className={`flex flex-col min-h-0 space-y-1 ${
        config.water_mark ? 'pb-[22px]' : 'pb-0'
      }`}
    >
      {chat.visible && <ChatList mobile={true} />}
      {showInputMsg && <ChatInputControl />}
    </div>
  )
}

function HotProducts() {
  const settings = useSettings()
  const config = useConfig()

  const products = useProducts()

  const ended = settings.settings?.live.status == 'ended'

  const isRecorded = ended && config?.recorded_live

  const hotProductRecorded = useSelector((v) => v.video.hotProduct)

  const { go } = useMimoRouter()

  const { t } = useTranslation()

  const hot =
    (isRecorded
      ? JSON.stringify(hotProductRecorded)
      : settings?.settings?.hot) || '[]'

  const hotArray = useMemo<HotData[]>(() => {
    let hotIdList: MimoId[]
    try {
      hotIdList = JSON.parse(hot)
      if (!isArray(hotIdList)) {
        throw new Error(`Invalid "hot" setting "${hot}", must be an array`)
      }
    } catch (e) {
      console.error(e)
      return []
    }
    return hotIdList
      .map((hotVariantId) => {
        return getHotData(products, hotVariantId)
      })
      .filter((v) => !!v)
  }, [hot, products])

  const tracking = useTracking()
  const currency = useConfig().settings?.currency?.code

  if (!config?.recorded_live && ended) {
    return null
  }

  return (
    <div
      role="list"
      aria-label={t('highlighted_products')}
      aria-hidden={hotArray.length === 0}
      className={`space-y-2 ${
        JSON.parse(hot).length > 0
          ? 'p-[5px] bg-immutableWhite-bgop bg-opacity-20 rounded-lg'
          : ''
      }`}
    >
      {hotArray.map((v, i) => (
        <div key={i} className="block">
          <HotProductCard
            title={v?.product.title}
            image={getProductThumb(v?.product, v?.variant)}
            price={v?.variant.price || 0}
            onClick={() => {
              tracking.track({
                event: 'view_item',
                currency: currency,
                value: v?.variant.price || 0,
                type: 'featured',
                items: [
                  {
                    item_id: v?.variant.sku,
                    item_name: v?.product.title,
                    currency: currency,
                    price: v?.variant.price,
                    image: getProductThumb(v?.product, v?.variant),
                  },
                ],
              })
              go(
                generatePath(PATHS.productWithVariant, {
                  id: v?.product.id,
                  variantId: v?.variant.id,
                })
              )
            }}
          />
        </div>
      ))}
    </div>
  )
}

type HotData = {
  product: Product
  variant: ProductVariant
} | null

function getHotData(products: Products, variantId: MimoId): HotData {
  let foundVariant: ProductVariant | undefined

  const foundProduct = products.find((product) => {
    const variant = product.variants.find((v) => v.id === variantId)

    foundVariant = variant || foundVariant
    return !!variant
  })

  if (!foundProduct || !foundVariant) {
    return null
  }

  return { product: foundProduct, variant: foundVariant }
}

type DisableLikeButton = {
  isDisabled?: boolean
}

function LikeButton({ isDisabled }: DisableLikeButton) {
  const { t } = useTranslation()
  const { like, isSendingLike } = useChat()
  const tracking = useTracking()

  const [imageUrl, setImageUrl] = useState(LikeIconLoading)
  const [timerForLike, setTimerForLike] = useState(false)

  const refreshImage = () => {
    setTimerForLike(true)
    setImageUrl((prevUrl) => `${prevUrl}?${Date.now()}`)
    setTimeout(() => {
      setTimerForLike(false)
    }, 2000)
  }

  return (
    <div className="bg-opacity-75 text-immutableBlack">
      <FloatingButton
        onClick={(v) => {
          like(v)
          tracking.track({ event: 'like_button', event_label: 'Like Button' })
          refreshImage()
        }}
        circled={false}
        icon={
          <HeartSwitcher
            isLoading={isSendingLike || timerForLike}
            imageUrl={imageUrl}
          />
        }
        disabled={isDisabled || timerForLike}
        ariaLabel={
          timerForLike
            ? t(
                'Several red hearts appear on the screen, indicating that you have successfully completed the action.'
              )
            : t('like')
        }
        label={t('like')}
      />
    </div>
  )
}

type Highlight = PropsWithChildren<{
  hot: boolean
}>

function ShowHighlight({ children, hot }: Highlight) {
  const { t } = useTranslation()
  const { accessibility } = useAccessibility()

  const [showHighlightWord, setShowHighlightWord] = useState(false)

  function handleHighlight() {
    setShowHighlightWord(!showHighlightWord)
  }
  return (
    <>
      <div className="relative">
        <button
          className={`w-[19px] h-[19px] rounded-full bg-immutableWhite-bgop bg-opacity-75 absolute top-[-13px] right-[-10px] justify-center items-center ${
            hot ? 'flex' : 'hidden'
          } ${!showHighlightWord && 'open'} highlight-btn text-immutableBlack ${
            accessibility.highContrast && '!bg-primary'
          }`}
          onClick={handleHighlight}
          aria-label={t('close')}
        >
          <PlusIcon />
        </button>
        {showHighlightWord && hot ? (
          <div
            className={`space-y-2 py-[5px] bg-immutableWhite-bgop bg-opacity-75 rounded-lg justify-center items-center ${
              hot ? 'flex' : 'hidden'
            }`}
          >
            <span className="text-[10px] text-immutableBlack font-semibold">
              {t('highlight_product')}
            </span>
          </div>
        ) : (
          <div className={`space-y-2`}>{children}</div>
        )}
      </div>
    </>
  )
}
