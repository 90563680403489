import BulletButton from '@components/bulletButton/BulletButton'
import ChatMask from '@components/chatMask/ChatMask'
import ChatMessage from '@components/chatMessage/ChatMessage'
import { ArrowDownIcon } from '@icons/ArrowDownIcon'
import clsx from 'clsx'
import { useChat } from 'providers/ChatProviders'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FixedPin } from '../../icons/FixedPin'

export default function ChatList({ className, mobile }: { mobile?: boolean, className?: string }) {
  const chat = useChat()

  const loading = chat.isSendingMessage

  const ref = useRef<HTMLDivElement | null>(null)
  const [autoScroll, setAutoScroll] = useState<boolean>(true)

  const onScroll = useCallback(
    (evt: Event) => {
      const elem = evt.currentTarget as HTMLDivElement
      const isBottom = elem.scrollHeight - elem.scrollTop <= elem.clientHeight

      if (isBottom !== autoScroll) {
        setAutoScroll(isBottom)
      }
    },
    [autoScroll, setAutoScroll]
  )

  const setRef = useCallback(
    (div: HTMLDivElement) => {
      if (div) {
        ref.current = div
        div.addEventListener('scroll', onScroll)
      } else {
        ref.current?.removeEventListener('scroll', onScroll)
        ref.current = null
      }
    },
    [onScroll]
  )

  const scrollToTop = useCallback(() => {
    ref.current?.scrollTo({ top: 10000000 })
  }, [])

  // Scrolls to top everytime message is sent
  useEffect(() => {
    if (loading === false) {
      setAutoScroll(true)
    }
  }, [setAutoScroll, loading])

  useEffect(() => {
    autoScroll && scrollToTop()
  }, [chat.messages, autoScroll, scrollToTop])

  const pinnedMessage = chat.pinnedMessage
   
  const handllePinnedMessage = (pinned: any) => {
    chat.chatUnpinnedMessage &&
      pinnedMessage &&
      chat.chatUnpinnedMessage(pinned)
  }

  return (
    <div
      className={clsx(
        'relative z-2-overlay flex flex-col min-h-0 space-y-3 ',
        className
      )}
    >
      <ChatMask ref={setRef}>
        <div className="space-y-2">
          {chat.messages?.map((v, i) => {
            return (
              <ChatMessage
                onClick={() => {
                  chat.chatMessageClick && chat.chatMessageClick(v)
                }}
                mobile={mobile}
                message={v}
                key={i}
              />
            )
          })}
        </div>
      </ChatMask>
      <div className='mt-4'>
        {pinnedMessage?.map((pinned, index) => {
          return (
            <div key={index}>
              <div
                className={`py-1 pt-2 pb-1 mt-2 rounded-2xl inline-flex self-center justify-between justify-items-center relative text-center break-words text-12 p-2 bg-chatAccent-wo text-chatText`}
                onClick={() => handllePinnedMessage(pinned)}
                onKeyDown={() => handllePinnedMessage(pinned)}
                aria-hidden="true"
              >
                <FixedPin />
                <span className="pl-2">{pinned?.text}</span>
              </div>
            </div>
          )
        })}
      </div>
      {!autoScroll && (
        <div className="absolute bottom-0 right-3 z-2-overlay opacity-60">
          <BulletButton onClick={scrollToTop}>
            <div className="text-10 py-1 px-1.5">
              <ArrowDownIcon />
            </div>
          </BulletButton>
        </div>
      )}
    </div>
  )
}
