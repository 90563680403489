/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable react-hooks/exhaustive-deps */
import ChatMask from '@components/chatMask/ChatMask'
import ChatMessage from '@components/chatMessage/ChatMessage'
import { ArrowDownIcon } from '@icons/ArrowDownIcon'
import clsx from 'clsx'
import { useChat } from 'providers/ChatProviders'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import BulletButton from '../../components/bulletButton/BulletButton'
import { ArrowUpIcon } from '../../icons/ArrowUpIcon'
import { CloseIcon } from '../../icons/CloseIcon'
import { FixedPin } from '../../icons/FixedPin'

export default function ChatList({
  className,
  mobile,
}: {
  mobile?: boolean
  className?: string
}) {
  const chat = useChat()

  const loading = chat.isSendingMessage

  const ref = useRef<HTMLDivElement | null>(null)
  const [autoScroll, setAutoScroll] = useState<boolean>(true)

  const [showFullPinnedMessage, setShowFullPinnedMessage] =
    useState<boolean>(true)
  const [isPinnedBoxVisible, setIsPinnedBoxVisible] = useState<boolean>(true)
  const [lastPinnedMessage, setLastPinnedMessage] = useState<any>()
  const [openBoxPinned, setOpenBoxPinned] = useState<boolean>(false)
  const [pinnedMessagesVisible, setPinnedMessagesVisible] = useState<
    Record<string, boolean>
  >({})
  const [hiddenPinnedMessages, setHiddenPinnedMessages] = useState<string[]>([])

  const onScroll = useCallback(
    (evt: Event) => {
      const elem = evt.currentTarget as HTMLDivElement
      const isBottom = elem.scrollHeight - elem.scrollTop <= elem.clientHeight

      if (isBottom !== autoScroll) {
        setAutoScroll(isBottom)
      }
    },
    [autoScroll, setAutoScroll]
  )

  const setRef = useCallback(
    (div: HTMLDivElement) => {
      if (div) {
        ref.current = div
        div.addEventListener('scroll', onScroll)
      } else {
        ref.current?.removeEventListener('scroll', onScroll)
        ref.current = null
      }
    },
    [onScroll]
  )

  const scrollToTop = useCallback(() => {
    ref.current?.scrollTo({ top: 10000000 })
  }, [])

  // Scrolls to top everytime message is sent
  useEffect(() => {
    if (loading === false) {
      setAutoScroll(true)
    }
  }, [setAutoScroll, loading])

  useEffect(() => {
    autoScroll && scrollToTop()
  }, [chat.messages, autoScroll, scrollToTop])

  const pinnedMessage = chat.pinnedMessage || []

  const handllePinnedMessage = (pinned: any) => {
    chat.chatUnpinnedMessage &&
      pinnedMessage &&
      chat.chatUnpinnedMessage(pinned)
  }

  const resetTimer = useCallback(() => {
    setTimeout(() => {
      setShowFullPinnedMessage(false)
      setIsPinnedBoxVisible(false)
      setOpenBoxPinned(false)
    }, 15000)
  }, [])

  useEffect(() => {
    const visiblePinnedMessages = pinnedMessage.filter(
      (msg) => !hiddenPinnedMessages.includes(msg.id.toString())
    )

    if (visiblePinnedMessages.length > 0) {
      const newPinnedMessage =
        visiblePinnedMessages[visiblePinnedMessages.length - 1]

      if (!lastPinnedMessage || lastPinnedMessage.id !== newPinnedMessage.id) {
        setIsPinnedBoxVisible(true)
        setLastPinnedMessage(newPinnedMessage)

        const initialVisibility = visiblePinnedMessages.reduce(
          (acc: Record<string, boolean>, msg) => {
            acc[msg.id] = true
            return acc
          },
          {}
        )
        setPinnedMessagesVisible(initialVisibility)
        resetTimer()
      }
    } else {
      setLastPinnedMessage(null)
    }
  }, [pinnedMessage, hiddenPinnedMessages, lastPinnedMessage, resetTimer])

  const getFirstLine = (text: string) => {
    const maxLength = window.innerWidth <= 768 ? 35 : 60

    if (text?.length > maxLength) {
      return text?.slice(0, maxLength) + '...'
    }
    return text
  }

  const handlePinnedBox = () => {
    if (isPinnedBoxVisible) {
      setIsPinnedBoxVisible(false)
      setOpenBoxPinned(false)
    } else {
      setIsPinnedBoxVisible(true)
      setOpenBoxPinned(true)
    }
  }

  const hidePinnedMessage = (id: string) => {
    setPinnedMessagesVisible((prevState) => ({
      ...prevState,
      [id]: false,
    }))

    setHiddenPinnedMessages((prev) => [...prev, id])
  }

  if (!pinnedMessage) return null

  return (
    <div
      className={clsx(
        'relative z-2-overlay flex flex-col min-h-0 space-y-3 ',
        className
      )}
    >
      <ChatMask ref={setRef} className="relative">
        <div className="space-y-2">
          {chat.messages?.map((v, i) => {
            return (
              <ChatMessage
                onClick={() => {
                  chat.chatMessageClick && chat.chatMessageClick(v)
                }}
                mobile={mobile}
                message={v}
                key={i}
              />
            )
          })}
        </div>
        {!autoScroll && (
          <div
            className={`sticky bottom-0
          left-[100%] z-2-overlay opacity-60 w-[30px]`}
          >
            <BulletButton onClick={scrollToTop}>
              <div className="text-10 py-1 px-1.5">
                <ArrowDownIcon />
              </div>
            </BulletButton>
          </div>
        )}
      </ChatMask>
      {pinnedMessage.length > 0 &&
        Object.values(pinnedMessagesVisible).some((isVisible) => isVisible) && (
          <div className="max-w-full w-full mx-auto bg-immutableWhite-bgop rounded-xl bg-opacity-75">
            <div
              className="flex items-center justify-between p-1"
              onClick={() => {
                handlePinnedBox()
                resetTimer()
              }}
            >
              <div className="flex items-center">
                <span className="ml-1 mt-1 text-immutableBlack text-sm">
                  <FixedPin />
                </span>
                <span className="ml-1 text-immutableBlack text-sm">
                  Mensagens Fixadas
                </span>
              </div>
              <div className="mr-1 text-12 text-immutableBlack">
                {isPinnedBoxVisible ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
            </div>
            <div className="flex flex-col self-stretch max-h-40 overflow-y-auto rounded-xl w-full pb-1">
              {!openBoxPinned ? (
                isPinnedBoxVisible ? (
                  <div className="flex justify-between items-center">
                    <div
                      className={`flex flex-col px-1 py-1 w-full justify-end items-start self-stretch rounded-lg break-words word-wrap`}
                      onClick={() => handllePinnedMessage(lastPinnedMessage)}
                      onKeyDown={() => handllePinnedMessage(lastPinnedMessage)}
                      aria-hidden="true"
                    >
                      <div className="flex p-1 py-1 px-1 items-start justify-between gap-4 self-stretch rounded-xl bg-white">
                        <span className="pl-2 text-black">
                          <b>{lastPinnedMessage?.sender}:</b>{' '}
                          {lastPinnedMessage?.text}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-between items-center">
                    <div
                      className={`flex flex-col p-1 w-full justify-end items-start mx-1 self-stretch rounded-lg bg-white`}
                      onClick={() => handllePinnedMessage(lastPinnedMessage)}
                      onKeyDown={() => handllePinnedMessage(lastPinnedMessage)}
                      aria-hidden="true"
                    >
                      <span className="pl-2 text-black break-words">
                        {getFirstLine(lastPinnedMessage.text)}
                      </span>
                    </div>
                  </div>
                )
              ) : (
                pinnedMessage?.map((pinned, index) =>
                  pinnedMessagesVisible[pinned.id] ? (
                    <div
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <div
                        className={`flex flex-col px-1 py-1 w-full justify-end items-start self-stretch rounded-lg break-words word-wrap`}
                        onClick={() => handllePinnedMessage(pinned)}
                        onKeyDown={() => handllePinnedMessage(pinned)}
                        aria-hidden="true"
                      >
                        <div className="flex p-1 py-1 px-1 items-start justify-between gap-4 self-stretch rounded-xl bg-white">
                          <span className="pl-2 text-black">
                            <b>{pinned?.sender}:</b> {pinned?.text}
                          </span>
                          <div className="p-1">
                            <button
                              className="ml-2 text-12 text-[#9C9CA0]"
                              onClick={() =>
                                hidePinnedMessage(pinned?.id as string)
                              }
                            >
                              <CloseIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                )
              )}
            </div>
          </div>
        )}
    </div>
  )
}
